.navbar-custom {
	position: fixed;
	top: 0;
	width: 100%;
}
.navbar-collapse {
	display: flex;
	justify-content: flex-end;
}

.link {
	font-size: 1.5rem;
	font-weight: 500;
	text-decoration: none;
	color: #000;
}

.link:hover {
	border-bottom: 2px solid #000;
}
