.feature {
	background-color: #f9f9f9;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease;
	margin-right: 4px;
	text-align: center;
	border-radius: 8px;
}
.feature:hover {
	transform: scale(1.05);
}
.feature-title {
	margin-top: 20px;
	font-weight: 600;
	text-align: center;
}
