.Carousal-feature {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0 20px;
	height: 600px;
	width: 350px;
}

.Carousal-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	width: 100%;
}

.Carousal-header {
	font-size: 2rem;
	font-weight: 600;
	margin-bottom: 10px;
	text-align: center;
}
