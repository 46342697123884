.back-to-top-button {
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-color: #007bff;
	color: #fff;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.back-to-top-button:hover {
	background-color: #0069d9;
}

/* hide-button using animation */
.hide-button {
	animation: hide 0.3s ease-in-out forwards;
}

@keyframes hide {
	0% {
		opacity: 1;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		transform: translateY(100px);
	}
}

/* Show button using animation */
.show-button {
	animation: show 0.3s ease-in-out forwards;
}

@keyframes show {
	0% {
		opacity: 0;
		transform: translateY(100px);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}
